// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"3TxI_lvym6WxCZZnMywgT"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://fa425f508ddf64352e93487eae82bbb5@o4504475760984064.ingest.us.sentry.io/4506415787868160',

  // Only enable Sentry in production
  enabled: process.env.NODE_ENV === 'production',

  // Don't send transactions to Sentry
  tracesSampleRate: 0,
  ignoreTransactions: ['*'],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  //only record replays for 5% of errors
  replaysOnErrorSampleRate: 0.05,

  // Only record error sessions
  replaysSessionSampleRate: 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
